// src/components/Navigation.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import logoImg from '../../assets/logo.png';
import './Navigation.css';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLangChange = (lng) => {
    i18n.changeLanguage(lng);
    setIsLangMenuOpen(false);
  };

  const toggleLangMenu = () => {
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={`navigation ${isMobile && isOpen ? 'open' : ''}`}>
      <div className="logo">
        <Link to="top" smooth={true} duration={700}>
          <img src={logoImg} alt="Logo" className="footer-logo" />
        </Link>
      </div>
      {isMobile && (
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <FaBars className="hamburger-icon" />
        </div>
      )}
      <ul className={`nav-links ${isMobile && isOpen ? 'open' : ''}`}>
        <li onClick={closeMenu}>
          <Link to="About" smooth={true} duration={500} onClick={closeMenu}>
            {t('About')}
          </Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="Products" smooth={true} duration={500} onClick={closeMenu}>
            {t('Products')}
          </Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="Price" smooth={true} duration={500} onClick={closeMenu}>
            {t('Price')}
          </Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="Contact" smooth={true} duration={500} onClick={closeMenu}>
            {t('Contact')}
          </Link>
        </li>
        <li className="lang-menu" onClick={toggleLangMenu}>
          <span className="selected-lang">{i18n.language.toUpperCase()}</span>
          {isLangMenuOpen && (
            <ul className="lang-options">
              <li onClick={() => handleLangChange('pl')}>PL</li>
              <li onClick={() => handleLangChange('en')}>EN</li>
              <li onClick={() => handleLangChange('de')}>DE</li>
              <li onClick={() => handleLangChange('es')}>ES</li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
