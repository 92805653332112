import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './WhyUs.css';
import img1 from '../../assets/WhyUs1.png';
import img2 from '../../assets/WhyUs2.png';
import img3 from '../../assets/WhyUs3.png';
import img4 from '../../assets/WhyUs4.png';
import { useTranslation } from 'react-i18next';

const Card = ({ image, title, text }) => {
    
    const controls = useAnimation();
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.8
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (inView && isMobile) {
            controls.start({ scale: 1.07 });
        } else {
            controls.start({ scale: 1.0 });
        }
    }, [controls, inView, isMobile]);

    return (
        <motion.div
            className="card"
            ref={ref}
            animate={controls}
            whileHover={!isMobile ? { scale: 1.07 } : {}}
            transition={{ type: "spring", stiffness: 150 }}
        >
            <img src={image} alt={title} className="card-image" />
            <div className="card-content">
                <h5>{title}</h5>
                <p>{text}</p>
            </div>
        </motion.div>
    );
};

const WhyUs = () => {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.8
    });

    return (
        <div>
            <div className="why-us-header" ref={ref}>
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1 }}
                >
                    {t('WhyUs')}
                </motion.h2>
            </div>
            <div className="cards-container">
                <Card image={img1}
                    title={t('Card1-1')}
                    text={t('Card1-2')} />

                <Card image={img2}
                    title={t('Card2-1')}
                    text={t('Card2-2')} />

                <Card image={img3}
                    title={t('Card3-1')}
                    text={t('Card3-2')}/>

                <Card image={img4}
                    title={t('Card4-1')}
                    text={t('Card4-2')} />
            </div>
        </div>
    );
};

export default WhyUs;
