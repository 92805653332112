import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import pdfFile from '../../assets/regulamin.pdf'

const Contact = () => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [isChecked, setIsChecked] = useState(false);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-in');
    const texts = [
        t("Contact-Text1"),
        t("Contact-Text2"),
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length);
                setFadeClass('fade-in');
            }, 500);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isChecked) {
            alert('Musisz zaakceptować warunki regulaminu przed wysłaniem wiadomości.');
            return;
        }

        emailjs.sendForm('ssl0.ovh.net', 'template_k2ajbjm', e.target, 'I8kzTClll4XD4OVlr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
                alert('Wystąpił błąd przy wysyłaniu wiadomości.');
            });

        setForm({
            name: '',
            email: '',
            message: '',
        });
        setIsChecked(false);
    };

    return (
        <div className="contact-container">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            required
                            placeholder={t('Name')}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            required
                            placeholder={t('Mail')}
                        />
                    </div>
                    <div className="input-group">
                        <textarea
                            name="message"
                            value={form.message}
                            onChange={handleChange}
                            required
                            placeholder={t('Your')}
                        ></textarea>
                    </div>
                    <div className="input-group checkbox-group">
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            required
                        />
                        <label htmlFor="termsCheckbox">
                            {t('Terms')}
                        </label>

                    </div>
                    <div className="cta-button4">
                        <button type="submit" className="cta-button-submit">{t('Button3')}</button>
                    </div>
                    <a className='Terms2' href={pdfFile} target="_blank" rel="noopener noreferrer">
                        <p style={{ paddingTop: '20px' }}>{t("Terms2")}</p>
                    </a>
                </form>
            </div>
            <div className="gradient-div-contact">
                <p className={`section-text-contact ${fadeClass}`}>
                    {texts[currentTextIndex]}
                </p>
            </div>
        </div>
    );
};

export default Contact;
