// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


i18n.use(initReactI18next).init({

  resources: {
    en: {
      translation: {
        //Global
        "Button": "Click Here",
        "Button2": "Write to Us",
        "Button3": "Send",
        // Naviagtion
        "About": "ABOUT",
        "Products": "PRODUCTS",
        "Price": "PRICE",
        "Contact": "CONTACT",
        //Hero
        "Welcome": "Connect with a single TOUCH",
        "Welcome2": "Transform traditional business cards into modern NFC solutions. Our NFC business cards redirect your clients to a personalized landing page where they can instantly access all your information. Share contact details, social profiles, and portfolios with a single touch!",
        //About Us
        "About1": "It's a startup creating interactive NFC business cards.",
        "About2": "Our company is a group of technology enthusiasts passionately creating innovative NFC business cards. We strive to change the way people share their contact information by offering modern and efficient solutions for both businesses and individuals. With our NFC business cards, your data is always at hand, ready to be shared quickly at any moment.",
        //WhyUS
        "WhyUs": "Why Us?",
        "Card1-1": "Innovation",
        "Card1-2": "Our NFC business cards are equipped with the latest technology, enabling instant data transfer.",
        "Card2-1": "Personalization",
        "Card2-2": "We offer a wide range of designs, colors, and materials to make your business cards unique and tailored to your needs.",
        "Card3-1": "High Quality",
        "Card3-2": "We ensure the highest standard of craftsmanship in our products, guaranteeing their durability and reliability.",
        "Card4-1": "Richer Content",
        "Card4-2": "Allows for more than just sharing contact information by offering personalized links.",
        //Products
        "P1-1": "NFC Business Cards",
        "P1-2": "We design modern NFC business cards that allow quick and easy sharing of contact information by simply tapping a device that supports NFC technology. This innovative solution will make you stand out from the competition.",
        "P2-1": "Customization",
        "P2-2": "Our NFC business cards can be fully customized to meet individual needs. Choose from a variety of designs, colors, and materials to create a card that best reflects the character of your company.",
        "P3-1": "Technical Support",
        "P3-2": "We offer technical support at every stage of using our products. Our team is always ready to help and answer any questions, ensuring seamless use of NFC business cards.",
        //Prices
        "Popular": "Best Offer",
        "Amount": "PIECES",
        "Currancy": "EURO",
        "Price1": "NFC Technology",
        "Price2": " Own graphic design or choose",
        "Price2-2": "from standard templates",
        "Price3": "IIndividual graphic design",
        "Price4": "Virtual Business Card",
        "Price5": "Customization of business card graphics",
        "Price6": " Adjustment of contact",
        "Price6-2": "details (email, phone, website)",
        "Price7": "Possibility to add social media",
        "Price7-2": "(e.g., LinkedIn, WhatsApp)",
        //Contact
        "Name": "Name",
        "Mail": "Email Adress",
        "Your": "Your Message",
        "Contact-Text1": "Write to us, and we will respond as soon as we can.",
        "Contact-Text2": "To order our NFC Business Cards or get more information, write to us!",
        "Terms": "I accept all of the Terms and Conditions and Privacy Policy ",
        "Terms2": "Terms and Conditions and Privacy Policy ",

        //Footer
        "FindUs": "Find Us",
        "Adress": "Związku Walki Młodych Street",
        "City": "Warsaw, Poland",
        "Laws": "All rights reserved. Terms and Conditions and Privacy Policy ",

        //=-=-=-=-=-=-=-USERS-=-=-=-=-=-=-=-=
        "Profession": "Real Estate Advisor",
        "Profile-Text": "As an experienced advisor at Home Select, I specialize in property rentals, adapting to dynamic market trends. My ability to quickly respond to changing client needs ensures professional advice and effective transaction execution.",
        "Phone": "Phone",
        "Email": "E-mail",

        //Emmusz
        "Profession-emmusz": "Real Estate Advisor",
        "Profile-Text-emmusz": "I help clients effectively sell their property: apartment, house, or land. I focus on active marketing. I work with other real estate agents. I promote listings on the largest real estate portals. I run social media campaigns. I organize open houses to increase the chances of a quick and favorable sale, rental, or purchase of a property. Thinking about selling and want your property to be visible? Call me, let's talk!",
        "Profile-Text-emmusz2": "I specialize in the effective sale of properties, including apartments, houses, and land. I use modern marketing strategies - I collaborate with other agencies, promote listings on top real estate portals, and run social media campaigns. I organize open houses to maximize the chances of a quick and favorable transaction. Interested in selling? Contact me to discuss the details.",
        "Profile-Text-emmusz3": "I specialize in successful property sales. I use modern marketing methods, promote listings on leading portals, and manage social media campaigns. I organize open houses to increase the chances of a quick and favorable transaction. Interested in selling? Contact me!"

      }
    },
    pl: {
      translation: {
        //Global
        "Button": "Kliknij tutaj",
        "Button2": "Napisz do nas",
        "Button3": "Wyślij",
        // Naviagtion
        "About": "O NAS",
        "Products": "PRODUKTY",
        "Price": "CENNIK",
        "Contact": "KONTAKT",
        //Hero
        "Welcome": "Łącz się jednym dotknięciem",
        "Welcome2": "Przekształć tradycyjne wizytówki w nowoczesne rozwiązania NFC. Nasze wizytówki NFC przenoszą Twoich klientów na personalizowaną podstronę, gdzie mogą natychmiast uzyskać wszystkie informacje o Tobie. Udostępniaj dane kontaktowe, profile społecznościowe i portfolio jednym dotknięciem!",
        "Button": "Kliknij tutaj",
        //About Us
        "About1": "to startup, tworzący interaktywne wizytówki NFC.",
        "About2": "Nasza firma to grupa entuzjastów technologii, z pasją tworzących innowacyjne wizytówki NFC. Dążymy do zmiany sposobu, w jaki ludzie udostępniają swoje dane kontaktowe, oferując nowoczesne i efektywne rozwiązania zarówno dla przedsiębiorstw, jak i osób prywatnych. Dzięki naszym wizytówkom NFC, Twoje dane są zawsze pod ręką, gotowe do szybkiego udostępnienia w każdej chwili.",
        //Why Us
        "WhyUs": "Dlaczego my?",
        "Card1-1": "Innowacyjność",
        "Card1-2": "Nasze wizytówki NFC są wyposażone w najnowszą technologię, co pozwala na błyskawiczne przekazywanie danych kontaktowych.",
        "Card2-1": "Personalizacja",
        "Card2-2": "Oferujemy szeroki wybór wzorów, kolorów i materiałów, aby Twoje wizytówki były wyjątkowe i dostosowane do Twoich potrzeb.",
        "Card3-1": "Wysoka jakość",
        "Card3-2": "Dbamy o najwyższy standard wykonania naszych produktów, co zapewnia ich trwałość i niezawodność.",
        "Card4-1": "Bogatsza Treść",
        "Card4-2": "Pozwala na przekazywanie więcej niż danych kontaktowych, oferując spersonalizowane linki.",
        //Products
        "P1-1": "Wizytówki NFC",
        "P1-2": "Projektujemy nowoczesne wizytówki NFC, które umożliwiają szybkie i łatwe udostępnianie danych kontaktowych poprzez zbliżenie do urządzenia obsługującego technologię NFC. To innowacyjne rozwiązanie, które wyróżni Cię na tle konkurencji.",
        "P2-1": "Personalizacja",
        "P2-2": "Nasze wizytówki NFC można w pełni dostosować do indywidualnych potrzeb. Wybierz z różnorodnych wzorów, kolorów i materiał łów, aby stworzyć wizytówkę, która najlepiej odzwierciedla charakter Twojej firmy.",
        "P3-1": "Wsparcie Techniczne",
        "P3-2": "Oferujemy wsparcie techniczne na każdym etapie korzystania z naszych produktów. Nasz zespół jest zawsze gotowy, aby pomóc i odpowiedzieć na wszelkie pytania, zapewniając bezproblemowe użytkowanie wizytówek NFC.",
        //Prices
        "Popular": "Najlepsza oferta",
        "Amount": "SZTUK",
        "Currancy": "PLN",
        "Price1": "Technologia NFC",
        "Price2": "Własna szata graficzna lub wybór",
        "Price2-2": "ze standardowych szablonów",
        "Price3": "Indywidualny projekt graficzny",
        "Price4": "Wirtualna Wizytówka",
        "Price5": "Personalizacja szaty graficznej wizytówki",
        "Price6": "Dostosowanie danych",
        "Price6-2": "kontaktowych (mail, telefon, strona)",
        "Price7": "Możliwośc dodania socialmediów",
        "Price7-2": "(np. LinekdIn, WhatsApp)",
        //Contact
        "Name": "Imię",
        "Mail": "Adres Email",
        "Your": "Twoja Wiadomość",
        "Contact-Text1": "Aby zamówić nasze Wizytówki NFC lub uzyskać więcej informacji, napisz do nas",
        "Contact-Text2": "Napisz do nas, odpowiemy, a odezwiemy się do Ciebie tak szybko jak będziemy mogli.",
        "Terms": "Akceptuję regulamin i politykę prywatności",
        "Terms2": "Regulamin i polityka prywatności",

        //Footer
        "FindUs": "Znajdź nas",
        "Adress": "ul. Związku Walki Młodych 6",
        "City": "Warszawa, Polska",
        "Laws": "Wszelkie prawa zastrzeżone. Regulamin i polityka prywatności",

        //=-=-=-=-=-=-=-USERS-=-=-=-=-=-=-=-=
        //Barmusz
        "Profession": "Doradca ds. Nieruchomości",
        "Profile-Text": "Doświadczony doradca w Home Select, specjalizujący się w wynajmie nieruchomości. Dostosowuję się do dynamicznych trendów rynkowych. Moja umiejętność szybkiego reagowania na zmieniające się potrzeby klientów zapewnia profesjonalne doradztwo i skuteczne przeprowadzanie transakcji.",
        "Phone": "Telefon",
        "Email": "Adres Email",

        //Pickcard
        "Profession-Pickcard": "Graphic Designer",
        "Profile-Text-Pickcard": "Tutaj możesz wstawić dowolny opis mówiący o twoim stanowisku pracy, zainteresowaniach lub ",

        //EmMusz
        "Profession-emmusz": "Doradca ds. Nieruchomości",
        "Profile-Text-emmusz": "Pomagam klientom efektywnie sprzedać ich nieruchomość: mieszkanie, dom czy działkę. Stawiam na aktywny marketing. Współpracuję z innymi agentami nieruchomości. Promuję oferty na największych portalach nieruchomości. Prowadzę kampanie w mediach społecznościowych. Organizuję dni otwarte aby zwiększyć szanse na szybką i korzystną sprzedaż, wynajem lub zakup nieruchomości.  Myślisz o sprzedaży, chcesz, aby Twoja nieruchomość była widoczna? Zadzwoń do mnie, porozmawiajmy!",
        "Profile-Text-emmusz2": "Specjalizuję się w efektywnej sprzedaży nieruchomości, w tym mieszkań, domów i działek. Stosuję nowoczesne strategie marketingowe - współpracuję z innymi agencjami, promuję oferty na czołowych portalach nieruchomości oraz prowadzę kampanie w mediach społecznościowych. Organizuję dni otwarte, aby maksymalizować szanse na szybką i korzystną transakcję. Zainteresowany sprzedażą? Skontaktuj się ze mną, by omówić szczegóły.",
        "Profile-Text-emmusz3": "Specjalizuję się w skutecznej sprzedaży nieruchomości. Korzystam z nowoczesnych metod marketingu, promuję oferty na wiodących portalach i w mediach społecznościowych. Organizuję dni otwarte, aby zwiększyć szanse na szybkie i korzystne transakcje. Zainteresowany sprzedażą? Skontaktuj się ze mną!",

      }
    },
    de: {
      translation: {
        //Global
        "Button": "Klicken Sie hier",
        "Button2": "Schreiben Sie uns",
        "Button3": "Senden",
        // Naviagtion
        "About": "ÜBER UNS",
        "Products": "PRODUKTE",
        "Price": "PREIS",
        "Contact": "KONTAKT",
        //Hero
        "Welcome": "VERBINDE DICH MIT EINER BERÜHRUNG",
        "Welcome2": "Verwandeln Sie traditionelle Visitenkarten in moderne NFC-Lösungen. Unsere NFC-Visitenkarten leiten Ihre Kunden auf eine personalisierte Zielseite weiter, auf der sie sofort alle Informationen über Sie abrufen können. Teilen Sie Kontaktdaten, soziale Profile und Portfolios mit nur einer Berührung!",
        "Button": "Klicken Sie hier",
        //About Us
        "About1": "Es ist ein Startup, das interaktive NFC-Visitenkarten erstellt.",
        "About2": "Unser Unternehmen ist eine Gruppe von Technologie-Enthusiasten, die mit Leidenschaft innovative NFC-Visitenkarten erstellen. Wir streben danach, die Art und Weise zu verändern, wie Menschen ihre Kontaktdaten teilen, indem wir moderne und effiziente Lösungen für Unternehmen und Privatpersonen anbieten. Mit unseren NFC-Visitenkarten sind Ihre Daten immer griffbereit und können jederzeit schnell geteilt werden.",
        //WhyUS
        "WhyUs": "Warum wir?",
        "Card1-1": "Innovativität",
        "Card1-2": "Unsere NFC-Visitenkarten sind mit der neuesten Technologie ausgestattet, die eine sofortige Datenübertragung ermöglicht.",
        "Card2-1": "Personalisierung",
        "Card2-2": "Wir bieten eine große Auswahl an Designs, Farben und Materialien, um Ihre Visitenkarten einzigartig und auf Ihre Bedürfnisse zugeschnitten zu machen.",
        "Card3-1": "Hohe Qualität",
        "Card3-2": "Wir sorgen für den höchsten handwerklichen Standard unserer Produkte, was ihre Langlebigkeit und Zuverlässigkeit garantiert.",
        "Card4-1": "Reichhaltigerer Inhalt",
        "Card4-2": "Ermöglicht mehr als nur das Teilen von Kontaktdaten, indem personalisierte Links angeboten werden.",
        //Products
        "P1-1": "NFC-Visitenkarten",
        "P1-2": "Wir entwerfen moderne NFC-Visitenkarten, die das schnelle und einfache Teilen von Kontaktinformationen durch einfaches Antippen eines Geräts, das NFC-Technologie unterstützt, ermöglichen. Diese innovative Lösung lässt Sie sich von der Konkurrenz abheben.",
        "P2-1": "Personalisierung",
        "P2-2": "Unsere NFC-Visitenkarten können vollständig an individuelle Bedürfnisse angepasst werden. Wählen Sie aus einer Vielzahl von Designs, Farben und Materialien, um eine Karte zu erstellen, die den Charakter Ihres Unternehmens am besten widerspiegelt.",
        "P3-1": "Technischer Support",
        "P3-2": "Wir bieten technischen Support in jeder Phase der Nutzung unserer Produkte. Unser Team steht immer bereit, um zu helfen und alle Fragen zu beantworten, und gewährleistet so eine nahtlose Nutzung der NFC-Visitenkarten.",
        //Prices
        "Popular": "Bestes Angebot",
        "Amount": "STÜCK",
        "Currancy": "EURO",
        "Price1": "NFC-Technologie",
        "Price2": "Eigene Grafikgestaltung oder Auswahl",
        "Price2-2": "aus Standardvorlagen",
        "Price3": "Individuelles Grafikdesign",
        "Price4": "Virtuelle Visitenkarte",
        "Price5": "Anpassung der Visitenkartengrafik",
        "Price6": "Anpassung der",
        "Price6-2": "Kontaktdaten (E-Mail, Telefon, Webseite)",
        "Price7": "Möglichkeit, soziale Medien hinzuzufügen",
        "Price7-2": "(z.B. LinkedIn, WhatsApp)",
        //Contact
        "Name": "Name",
        "Mail": "E-Mail-Adresse",
        "Your": "Ihre Nachricht",
        "Contact-Text1": "Schreiben Sie uns, und wir werden so schnell wie möglich antworten.",
        "Contact-Text2": "Um unsere NFC-Visitenkarten zu bestellen oder weitere Informationen zu erhalten, schreiben Sie uns!",
        "Terms": "Ich akzeptiere die Geschäftsbedingungen und die",
        "Terms2": "Geschäftsbedingungen und die",
        //Footer
        "FindUs": "Finden Sie uns",
        "Adress": "Związku Walki Młodych 6 Straße",
        "City": "Warschau, Polen",
        "Laws": "Alle Rechte vorbehalten. Geschäftsbedingungen und Datenschutzrichtlinie",

        //=-=-=-=-=-=-=-USERS-=-=-=-=-=-=-=-=
        //Barmusz
        "Profession": "Immobilienberater",
        "Profile-Text": "Als erfahrener Berater bei Home Select spezialisiere ich mich auf die Vermietung von Immobilien und passe mich dynamischen Markttrends an. Meine Fähigkeit, schnell auf sich ändernde Kundenbedürfnisse zu reagieren, gewährleistet professionelle Beratung und effektive Transaktionsabwicklung.",
        "Phone": "Telefon",
        "Email": "E-mail",

        //Emmusz
        "Profession-emmusz": "Immobilienberater",
        "Profile-Text-emmusz": "Ich spezialisiere mich auf den Verkauf von Immobilien – Wohnungen, Häusern und Grundstücken. Ich nutze moderne Marketingstrategien, arbeite mit anderen Agenturen zusammen, bewerbe Angebote auf den wichtigsten Portalen und führe Social-Media-Kampagnen durch. Ich organisiere offene Besichtigungen, um die Chancen auf eine schnelle, vorteilhafte Transaktion zu maximieren. Interessiert am Verkauf? Kontaktieren Sie mich!"

      }
    },
    es: {
      translation: {
        //Global
        "Button": "Haz clic aquí",
        "Button2": "Escríbenos",
        "Button3": "Enviar",
        // Naviagtion
        "About": "SOBRE NOSOTROS",
        "Products": "PRODUCTOS",
        "Price": "PRECIO",
        "Contact": "CONTACTO",
        //Hero
        "Welcome": "CONÉCTATE CON UN SOLO TOQUE",
        "Welcome2": "Transforma las tarjetas de visita tradicionales en soluciones modernas NFC. Nuestras tarjetas de visita NFC redirigen a tus clientes a una página personalizada donde pueden acceder instantáneamente a toda tu información. ¡Comparte detalles de contacto, perfiles sociales y portafolios con un solo toque!",
        //About Us
        "About1": "Es una startup que crea tarjetas de visita NFC interactivas.",
        "About2": "Nuestra empresa es un grupo de entusiastas de la tecnología que crean con pasión tarjetas de visita NFC innovadoras. Nos esforzamos por cambiar la forma en que las personas comparten su información de contacto, ofreciendo soluciones modernas y eficientes tanto para empresas como para particulares. Con nuestras tarjetas de visita NFC, tus datos están siempre a mano, listos para ser compartidos rápidamente en cualquier momento.",
        //WhyUS
        "WhyUs": "¿Por qué nosotros?",
        "Card1-1": "Innovación",
        "Card1-2": "Nuestras tarjetas de visita NFC están equipadas con la última tecnología, lo que permite la transferencia instantánea de datos.",
        "Card2-1": "Personalización",
        "Card2-2": "Ofrecemos una amplia gama de diseños, colores y materiales para que tus tarjetas de visita sean únicas y adaptadas a tus necesidades.",
        "Card3-1": "Alta Calidad",
        "Card3-2": "Nos aseguramos de que nuestros productos tengan el más alto estándar de fabricación, garantizando su durabilidad y fiabilidad.",
        "Card4-1": "Contenido Más Rico",
        "Card4-2": "Permite compartir más que solo información de contacto, ofreciendo enlaces personalizados.",
        //Products
        "P1-1": "Tarjetas de Visita NFC",
        "P1-2": "Diseñamos tarjetas de visita NFC modernas que permiten compartir información de contacto de manera rápida y fácil simplemente tocando un dispositivo que soporte la tecnología NFC. Esta solución innovadora te hará destacar frente a la competencia.",
        "P2-1": "Personalización",
        "P2-2": "Nuestras tarjetas de visita NFC se pueden personalizar completamente para satisfacer las necesidades individuales. Elige entre una variedad de diseños, colores y materiales para crear una tarjeta que refleje mejor el carácter de tu empresa.",
        "P3-1": "Soporte Técnico",
        "P3-2": "Ofrecemos soporte técnico en cada etapa del uso de nuestros productos. Nuestro equipo siempre está listo para ayudar y responder cualquier pregunta, asegurando un uso sin problemas de las tarjetas de visita NFC.",
        //Prices
        "Popular": "Mejor oferta",
        "Amount": "PIEZAS",
        "Currancy": "EURO",
        "Price1": "Tecnología NFC",
        "Price2": "Diseño gráfico propio o elegir",
        "Price2-2": "entre plantillas estándar",
        "Price3": "Diseño gráfico individual",
        "Price4": "Tarjeta de visita virtual",
        "Price5": "Personalización",
        "Price6": "Ajuste de los datos de contacto",
        "Price6-2": "(correo electrónico, teléfono, sitio web)",
        "Price7": "Posibilidad de añadir redes sociales",
        "Price7-2": "(p. ej., LinkedIn, WhatsApp)",
        //Contact
        "Name": "Nombre",
        "Mail": "Email",
        "Your": "Tu Mensaje",
        "Contact-Text1": "Escríbenos y te responderemos lo antes posible.",
        "Contact-Text2": "Para pedir nuestras Tarjetas de Visita NFC o obtener más información, ¡escríbenos!",
        "Terms": "Acepto los Términos y la Política de Privacidad",
        "Terms2": "Términos y Condiciones y Política de Privacidad",

        //Footer
        "FindUs": "Encuéntranos",
        "Adress": "calle Związku Walki Młodych 6",
        "City": "Varsovia, Polonia",
        "Laws": "Todos los derechos reservados. Términos y Condiciones y Política de Privacidad",


        //=-=-=-=-=-=-=-USERS-=-=-=-=-=-=-=-=
        //Barmusz
        "Profession": "Asesor Inmobiliario",
        "Profile-Text": "Como asesor experimentado en Home Select, me especializo en alquileres de propiedades, adaptándome a las tendencias dinámicas del mercado. Mi capacidad para responder rápidamente a las necesidades cambiantes de los clientes garantiza un asesoramiento profesional y una ejecución efectiva de las transacciones.",
        "Phone": "Teléfono",
        "Email": "E-mail",

        //Emmusz
        "Profession-emmusz": "Asesor Inmobiliario",
        "Profile-Text-emmusz": "Ayudo a mis clientes a vender su propiedad de manera efectiva: apartamento, casa o terreno. Me centro en el marketing activo. Colaboro con otros agentes inmobiliarios. Promuevo las ofertas en los principales portales inmobiliarios. Realizo campañas en redes sociales. Organizo jornadas de puertas abiertas para aumentar las posibilidades de una venta, alquiler o compra rápida y favorable. ¿Estás pensando en vender y quieres que tu propiedad sea visible? ¡Llámanos, hablemos!",
      }
    }
  },
  lng: 'pl',
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
