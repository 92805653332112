import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, PresentationControls } from '@react-three/drei';
import './HeroSection.css';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import HeroCard from '../../assets/HeroCard.png';


// Custom hook to determine if the device jest mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function Model(props) {
  const modelRef = useRef();
  const { scene } = useGLTF('/cardModel.glb');

  useEffect(() => {
    scene.position.set(0, props.isMobile ? 0 : 0, 0, 0); // Adjust position based on device
    scene.rotation.set(0, Math.PI, 0); // Adjust rotation to face the model front
    scene.scale.set(props.isMobile ? 1 : 0.85, props.isMobile ? 1 : 0.85, props.isMobile ? 1 : 0.85); // Adjust scale to make the model smaller
  }, [scene, props.scale, props.isMobile]);

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y -= 0.006; // Horizontal rotation
    }
  });

  return <primitive ref={modelRef} object={scene} {...props} />;
}

const HeroSection = () => {
  const { t } = useTranslation();
  const cameraRef = useRef();
  const isMobile = useIsMobile();
  const modelScale = 1; // Same scale for both mobile and desktop

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(0, 0, isMobile ? 5 : 3); // Adjust camera position
      cameraRef.current.fov = isMobile ? 75 : 45; // Adjust FOV for better visibility on mobile
      cameraRef.current.updateProjectionMatrix();
    }
  }, [isMobile]);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.addEventListener('wheel', handleWheel);
    }
    return () => {
      if (canvas) {
        canvas.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <div className="hero-section" id="hero-section">
      <div className="hero-text">
        <h1 className="animate-charcter">{t('Welcome')}</h1>
        <h3 className="">{t('Welcome2')}</h3>
        <div className="button-container">
          <div className="cta-button">
            <Link to="Contact" smooth={true} duration={700}>
              {t('Button')}
            </Link>
          </div>
        </div>
      </div>
      <div className="right-section">
        <Canvas
          dpr={[2, 3]}
          camera={{ fov: 45, position: [0, 0, 11], ref: cameraRef }}
          style={{ height: isMobile ? '320px' : '700px' }} // Adjust canvas height for mobile view
          onWheel={(e) => e.preventDefault()}
        >
          <color attach="background" args={["#101010"]} />
          <ambientLight intensity={5.5} />
          <hemisphereLight skyColor={0xb1e1ff} groundColor={0xb97a20} intensity={0.8} />
          <directionalLight position={[5, 5, 5]} intensity={1.0} />
          <directionalLight position={[-5, 5, 5]} intensity={1.0} />
          <directionalLight position={[0, 5, -5]} intensity={1.0} />
          <spotLight position={[10, 10, 10]} angle={0.3} intensity={1.5} castShadow />
          <PresentationControls speed={0.75} global zoom={false} enableZoom={false} polar={[0, 0]}>
            <Model scale={modelScale} isMobile={isMobile} />
          </PresentationControls>
          <OrbitControls enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} rotateSpeed={0.5} />
        </Canvas>
      </div>
    </div>
  );
};

export default HeroSection;
