import React, { useEffect, useState } from 'react';
import './Products.css';
import productVideo1 from '../../assets/video1.mp4';
import productImage2 from '../../assets/Prod1.png';
import productImage3 from '../../assets/Prod2.png';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Products = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  return (
    <div>
      <div className="section-container">
        <div className="products-container">
          <div className="text-section">
            <h1>{t("P1-1")}</h1>
            <h2>{t("P1-2")}</h2>
            <div className="cta-button">
              <Link to="Contact" smooth={true} duration={700}>
                {t('Button')}
              </Link>
            </div>
          </div>
          <div className="video-section">
            <video 
              src={productVideo1} 
              alt="Product" 
              muted 
              loop 
              autoPlay={!isMobile} // autoplay only on non-mobile devices
            />
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="products-container reverse">
          <div className="text-section">
            <h1>{t("P2-1")}</h1>
            <h2>{t("P2-2")}</h2>
            <div className="cta-button-reverse">
              <Link to="Contact" smooth={true} duration={700}>
                {t('Button2')}
              </Link>
            </div>
          </div>
          <div className="image-section">
            <img src={productImage2} alt="Product" />
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="products-container">
          <div className="text-section">
            <h1>{t("P3-1")}</h1>
            <h2>{t("P3-2")}</h2>
            <div className="cta-button">
              <Link to="Contact" smooth={true} duration={700}>
                {t('Button')}
              </Link>
            </div>
          </div>
          <div className="image-section">
            <img src={productImage3} alt="Product" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
