import React from 'react';
import { Link } from 'react-scroll';
import logoImg from '../../assets/logo.png'; // Zamień ścieżkę na odpowiednią
import { FaFacebook, FaInstagram, FaTwitter, FaEnvelope, FaLinkedin } from 'react-icons/fa'; // Import ikon z react-icons
import './Footer.css'; // Import stylów
import { useTranslation } from 'react-i18next';
import pdfFile from '../../assets/regulamin.pdf'

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <Link to="top" smooth={true} duration={500} className="footer-logo-link">
            <img src={logoImg} alt="Logo" className="footer-logo" />
          </Link>
        </div>
        <div className="footer-right">
          <h6>{t("FindUs")}</h6>
          <p>
            +48 669 206 212<br />
            {t("Adress")}<br />
            {t("City")}<br />
            info@pickcard.pl
          </p>
          <div className="social-icons">
            <a href="mailto:witaj@naprawdeswietnastrona.pl" target="_blank" rel="noopener noreferrer">
              <FaEnvelope />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <a href={pdfFile} target="_blank" rel="noopener noreferrer">
          <p>© 2024 PICKCARD. {t("Laws")}</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
