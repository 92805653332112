import React from 'react';
import './Cennik.css'; // Assuming you have a CSS file for styling
import { useTranslation } from 'react-i18next';

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="pricing">
        <div className="plan">
          <h_price>30 {t('Amount')}</h_price>
          <div className="price">120 PLN</div>
          <ul className="features">
            <li><i className="fas fa-check-circle"></i>{t('Price1')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price2')} <br /> &nbsp; &nbsp; &nbsp; {t('Price2-2')}</li>
            <li><i className="fas fa-times-circle"></i> {t('Price3')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price4')}</li>
            <li><i className="fas fa-times-circle"></i> {t('Price5')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price6')} <br />&nbsp; &nbsp; &nbsp; {t('Price6-2')}</li>
          </ul>
        </div>
        <div className="plan">
          <h_price>100 {t('Amount')}</h_price>
          <div className="price">350 PLN</div>
          <ul className="features">
            <li><i className="fas fa-check-circle"></i> {t('Price1')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price2')}<br /> &nbsp; &nbsp; &nbsp;  {t('Price2-2')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price3')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price4')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price5')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price7')} <br /> &nbsp; &nbsp; &nbsp;{t('Price7-2')}</li>
          </ul>
        </div>
        <div className="plan popular">
          <span>{t('Popular')}</span>
          <h_price>500 {t('Amount')}</h_price>
          <div className="price">1375 PLN</div>
          <ul className="features">
            <li><i className="fas fa-check-circle"></i> {t('Price1')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price2')}<br /> &nbsp; &nbsp; &nbsp;  {t('Price2-2')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price3')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price4')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price5')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price7')} <br /> &nbsp; &nbsp; &nbsp;{t('Price7-2')}</li>
          </ul>
        </div>
        <div className="plan">
          <h_price>2000 {t('Amount')}</h_price>
          <div className="price">3600 PLN</div>
          <ul className="features">
            <li><i className="fas fa-check-circle"></i> {t('Price1')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price2')}<br /> &nbsp; &nbsp; &nbsp;  {t('Price2-2')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price3')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price4')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price5')}</li>
            <li><i className="fas fa-check-circle"></i> {t('Price7')} <br /> &nbsp; &nbsp; &nbsp;{t('Price7-2')}</li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default Pricing;
