import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import BarmuszPage from './userPages/barmusz/barmusz.js';
import Pickcard from './userPages/pickcard/pickcard-profile.js';
import EmilaPage from './userPages/emmusz/emmusz.js'

import './i18n'; // Importuj konfigurację i18n

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/barmusz" element={<BarmuszPage />} />
        <Route path="/pickcard-profile" element={<Pickcard />} />
        <Route path="/emmusz" element={<EmilaPage />} />
     
        {/* Inne ścieżki */}
      </Routes>
    </Router>
  );
}

export default App;
