import React, { useEffect, useRef, useState } from 'react';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const aboutUsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            setIsVisible(entry.isIntersecting);
        }, { threshold: 0.6 });

        if (aboutUsRef.current) {
            observer.observe(aboutUsRef.current);
        }

        return () => {
            if (aboutUsRef.current) {
                observer.unobserve(aboutUsRef.current);
            }
            observer.disconnect();
        };
    }, []);

    return (
        <div className={`about-us ${isVisible ? 'animate' : 'fade-out'}`} ref={aboutUsRef} id="about-us">
            <h4 className={`section-header ${isVisible ? 'slide-from-left' : 'fade-out'}`}>
                <span className="header-highlight">PICKCARD</span> {t('About1')}
            </h4>
            <div className={`gradient-div ${isVisible ? 'slide-from-right' : 'fade-out'}`}>
                <p className="section-text">
                {t('About2')}
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
